.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Footer */
.site-footer {
  background-color: var(--strong-background-color);
  /* Light grey background */
  color: var(--text-color);
  /* Dark grey text */
  text-align: center;
  padding: 20px 0;
  margin-top: 40px;
  /* Adds some space above the footer */
  border-top: 1px solid #dddddd;
  /* Light grey border at the top of the footer */
}

.footer-content a {
  color: var(--strong-text-color);
  /* Link color */
  margin: 0 10px;
  /* Spacing between links */
  text-decoration: none;
}

.footer-content a:hover,
.footer-content a:focus {
  text-decoration: underline;
}

.social-links {
  align-items: center;
  margin-left: 20px;
  /* Add some space between the icons and the title */
}

.social-links a {
  color: var(--link-color);
  /* Match with your nav link color */
  margin: 0 10px;
  /* Adjust the space between icons */
  transition: color 0.3s ease;
}

.social-links a i {
  font-size: 1.5em;
  /* Adjust the size as needed */
}

.social-links a:hover {
  color: #888888;
  /* Color for icon hover state */
}