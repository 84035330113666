.home-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
    /* Align items on both ends */
    gap: 20px;
    /* Adds space between the text content and logo */
    min-height: 100vh;
    overflow: hidden;
}

.home-text {
    width: 50%;
    /* Adjust the width as needed */
    padding: 20px;
    /* Add some padding */

}

.home-text h1 {
    margin-bottom: 20px;
    /* Space below the header */
    font-size: 5.5rem;
    /* Large, eye-catching font size */
    color: var(--strong-text-color);
    /* Strong color for contrast */
    /* text-shadow: rgb(125, 125, 125) 1px 0 10px; */
    text-align: center;

}

.home-text p {
    font-size: 1.5rem;
    /* Slightly larger font size for readability */
    color: var(--text-color);
    /* Medium-dark color for the text */
    max-width: 600px;
    /* Max width to control line length */
    margin: 0 auto 30px;
    /* Center the paragraph with auto left/right margins */
    text-align: center;
}

.home-search-container {
    /* Full height of the viewport */
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
    /* Align items on both ends */
    padding: 40px;
    /* Add some padding */
}

.home-list {
    list-style-type: disc;
    text-align: left;
    margin: 20px;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.home-list.visible {
    max-height: 500px;
    /* Adjust based on the height of your list */
    transition: max-height 0.5s ease-in;
}

.home-list li {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: var(--text-color);
}

.toggle-list-btn {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 600;

    transition: background-color 0.3s;
}

.toggle-list-btn:hover {
    /* background-color: var(--db-border-color); */
    text-decoration: underline;
}

.home-photo {
    width: 100%;
    /* Take up all available width */
    height: 100%;
    /* Take up all available height */
    object-fit: cover;
    /* Cover the space without stretching */
}


.explore-btn {
    background-color: #000000;
    color: #ffffff;
    padding: 15px 15px;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.25rem;
}

.explore-btn:hover {
    background-color: #333;
    /* Slight hover effect */
    border-color: var(--strong-text-color);
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .home-container {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* Adjust alignment for mobile */
        width: 100%;
    }

    .home-text {
        width: 75%;
        /* Full width for mobile */
        text-align: center;
        /* Center text for better readability */
    }

    .home-text h1 {
        font-size: 4rem;
        /* Adjust font size for smaller screens */
    }

    .home-text p {
        font-size: 1.25rem;
        /* Adjust font size for smaller screens */
    }

    .toggle-list-btn {
        font-size: 1.25rem;
    }

    .home-list li {
        font-size: 1.25rem;
    }

    .explore-btn {
        font-size: 1rem;
    }

    .home-image-container {
        display: none;
        /* Hide the image container on mobile */
    }

    .home-search-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 75%;
        max-width: 100%;
        padding: 20px;
        /* margin: 0 auto; */
    }
}