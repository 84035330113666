/* styles/About.css */
.about-section {
    padding: 20px 100px 10px 100px;
    width: 100%;
    box-sizing: border-box;
}

.about-section h2 {
    font-size: 3rem;
    color: var(--strong-text-color);
    margin-bottom: 20px;
    text-align: left;
}

.about-section p {
    color: var(--text-color);
    font-size: 1.25rem;
    line-height: 1.6;
    max-width: 100%;
    margin-bottom: 20px;
    text-align: left;
    overflow-wrap: break-word;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .about-section {
        padding: 10px;
        text-align: left;
    }

    .about-section h2 {
        font-size: 2rem;
        margin-bottom: 10px;
    }

    .about-section p {
        font-size: 1rem;
        margin-bottom: 10px;
    }
}