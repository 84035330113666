/* LoadingIndicator.css */

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    /* Adjust the height as needed */
    font-size: 4rem;
    font-weight: bold;
}

.loading-text {
    color: var(--strong-text-color)
}