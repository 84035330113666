.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--strong-background-color);
    box-shadow: var(--shadow-color);
    /* padding: 0 20px; */
    z-index: 1000;
    /* Ensure navbar stays on top of other content */
    /* min-height: 50px; */
}

.navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-grow: 1;
    /* Allows the links to take up available space and center */
}

.navbar-links button {
    background: none;
    border: none;
    text-decoration: none;
    color: var(--text-color);
    /* Adjust link color as needed */
    font-weight: bold;
    font-size: 1rem;
    transition: color 0.3s;
    /* Smooth transition for the color change */
}

.navbar-links button:hover,
.navbar-links button:focus {
    color: var(--strong-text-color);
    text-decoration: underline;
}

.navbar-contact button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: var(--strong-text-color);
    /* Adjust button background color as needed */
    color: var(--background-color);
    font-weight: bolder;
    border: none;
    border-radius: 0px;
    transition: background-color 0.5s ease;
    /* Smooth transition for background color change */
}

.navbar-contact button:hover,
.navbar-contact button:focus {
    /* Ensure accessibility for keyboard navigation */
    background-color: var(--link-color);
    /* Darker shade on hover/focus */
}

.navbar-title {
    padding-left: 10px;
    font-size: 24px;
    font-weight: bold;
    color: var(--strong-text-color)
}

.dark-mode-toggle {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1em;
    cursor: pointer;
    margin-right: 20px;
    /* Space between toggle and contact button */
}

.dark-mode-toggle:hover {
    color: var(--link-color);
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .navbar {
        /* width: 90%; */
        align-items: flex;
    }

    .navbar-title {
        display: none;
        /* Hide the title on smaller screens */
    }

    .navbar-contact button {
        font-size: 0;
        /* Hide the text inside the button */
        padding: 10px;
        /* Adjust padding */
        width: 40px;
        /* Fixed width for the icon */
        height: 40px;
        /* Fixed height for the icon */
    }

    .navbar-contact button:before {
        content: "\f007";
        /* FontAwesome user icon */
        font-family: "Font Awesome 5 Free";
        /* Ensure this matches your FontAwesome version */
        font-weight: 900;
        /* FontAwesome solid style */
        font-size: 16px;
        /* Icon size */
        color: var(--background-color);
        /* Icon color */
    }

    .navbar-links {
        justify-content: left;
        gap: 10px;
        /* Smaller gap between links */
    }
}