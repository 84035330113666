@import url('https://fonts.googleapis.com/css2?family=UnifrakturCook:wght@700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Jacquard+24&display=swap'); */

.gothic-font {
  font-family: 'UnifrakturCook', cursive;
  /* text-shadow: rgb(125, 125, 125) 1px 0 10px; */
}

/* Default light mode */
:root {
  --background-color: #f5f5f5;
  --strong-background-color: #fff;
  --text-color: #333;
  --strong-text-color: #000;
  --link-color: #333333;
  --shadow-color: 0 2px 4px rgba(0, 0, 0, 0.1);
  --light-db-color: #dcdcdc;
  --strong-db-color: #d4d4d4;
  --db-border-color: #ccc;
  --db-hover-color: #c6c6c6;
  --form-border-color: #ccc;
}

/* Dark mode */
[data-theme="dark"] {
  --background-color: #1c1c1c;
  --strong-background-color: #000;
  --text-color: #dddddd;
  --strong-text-color: #ffffff;
  --link-color: #cccccc;
  --shadow-color: 0 2px 4px rgba(0, 0, 0, 0.5);
  --light-db-color: #4e4e4e;
  --strong-db-color: #555555;
  --db-border-color: #333;
  --db-hover-color: #454545;
  --form-border-color: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('/public/camo_bg.jpeg'); */
  background-color: var(--background-color);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}