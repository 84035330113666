/* styles/AboutPage.css */
.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    box-sizing: border-box;
}

.back-to-songs {
    display: block;
    position: fixed;
    top: 30px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 0;
    background: rgba(110, 110, 110, 0.2);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    z-index: 10;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.back-to-songs.visible {
    opacity: 1;
}

.back-to-songs:hover {
    background: rgba(100, 100, 100, 0.4);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .about-container {
        margin: 0 10px;
        width: calc(100% - 20px);
    }

    .back-to-songs {
        top: 10px;
        padding: 5px 0;
    }
}