/* General container styles */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.database-container {
    max-height: 90vh;
    overflow-y: auto;
    margin: 60px 30px;
    border-radius: 10px;
    box-sizing: border-box;
    overflow-x: hidden;
    /* Ensure no extra scrollbars */
}

/* Scrollbar styles */
.database-container::-webkit-scrollbar {
    width: 16px;
}

.database-container::-webkit-scrollbar-thumb {
    background: #666;
}

.database-container::-webkit-scrollbar-thumb:hover {
    background: #5a5a5a;
}

.database-container::-webkit-scrollbar-track {
    background: #000000;
    /* Dark grey background for the track */
}

/* Table styles */
.songs-table {
    width: 100%;
    border-collapse: collapse;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    color: var(--strong-text-color);
    box-sizing: border-box;
    overflow: scroll;
    overflow-y: auto;
    /* Ensure no extra scrollbars */
}

.songs-table th,
.songs-table td {
    text-align: center;
    padding: 12px 12px;
    /* Increase padding for more space */
    border-bottom: 1px solid var(--db-border-color);
}

/* Add styles for links */
.songs-table a {
    color: inherit;
    text-decoration: none;
}

.songs-table a:hover {
    color: var(--text-color);
    text-decoration: underline;
}

/* Sticky header styles */
.songs-table thead th {
    background-color: #000000;
    /* Dark background for contrast */
    color: #FFFFFF;
    /* White text for readability */
    text-transform: uppercase;
    /* Make headers stand out */
    font-weight: 600;
    /* Slightly bolder */
    position: sticky;
    top: 0;
    z-index: 2;
}

/* Zebra striping with consideration for sticky header */
.songs-table tr:nth-child(odd) {
    background-color: var(--light-db-color);
}

.songs-table tr:nth-child(even) {
    background-color: var(--strong-db-color);
    /* Ensure there's a distinction for even rows */
}

.songs-table tr:hover {
    background-color: var(--db-hover-color);
    transition: background-color 0.5s;
    /* Smooth transition */
}

/* Active sort column highlighting */
.songs-table th.active-sort {
    background-color: darkgrey;
}

.songs-table th:hover {
    background-color: #222222;
    /* Slightly lighter shade on hover */
    cursor: pointer;
    /* Change cursor to indicate action */
    transition: background-color 0.5s;
    /* Smooth transition */
}

/* Adjusted select box styles for filters */
.songs-table select {
    width: 100%;
    padding: 4px;
    margin: 0;
    box-sizing: border-box;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.songs-table img {
    width: 50px;
    /* Example size */
    height: auto;
    border-radius: 4px;
    /* Optional: Round corners */
}

.songs-table select {
    background-color: #f9f9f9;
    /* Background color */
    border: 1px solid #ddd;
    /* Border color and width */
    margin-top: 6px;
    /* Add space under text */
}

/* Might need an additional wrapper around the select for custom arrow styling in non-WebKit browsers */

@media screen and (max-width: 768px) {

    .songs-table {
        width: 100%;
        font-weight: 500;
        font-size: .5rem;
    }

    .database-container {
        max-height: 85vh;
        overflow-x: auto;
        margin: 0px 15px;
    }

    .songs-table th,
    .songs-table td {
        padding: 10px 2px;
    }

    /* .songs-table th:nth-child(n+2),
    .songs-table td:nth-child(n+2) {
        max-width: 250px;
    } */
}